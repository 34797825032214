import { useCallback, useEffect, useState } from 'react';

import { useReportSetupContext } from '../ReportSetupContext';
import { useReportWidgetContext } from '../ReportWidgetContext';
import { ReportStatus } from 'src/@types/enums';
import jtbdService from 'src/services/jtbd.service';
import { message } from 'antd';

export const useGenerateReportApi = (): [{ isLoading: boolean; error: unknown }, () => Promise<void>] => {
    const { reportState, isWithInsightReportState, isWithTranscriptState } = useReportSetupContext();

    const [isWithInsightReport] = isWithInsightReportState;
    const [isWithTranscript] = isWithTranscriptState;
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<unknown>();

    const { reportId, report, reloadReport } = useReportWidgetContext();

    const fetchData = useCallback(async () => {
        setError(null);
        setIsLoading(true);

        if (!reportId) {
            return console.log('Record id is undefined. Report can not be generated');
        }
        try {
            switch (report?.status) {
                case ReportStatus.Uploaded:
                    await jtbdService.generateReport(reportId, {
                        ...reportState,
                        is_with_insight_report: isWithInsightReport,
                    });
                    break;
                case ReportStatus.Finished:
                    await jtbdService.reGenerateReport(reportId, {
                        ...reportState,
                        is_with_insight_report: isWithInsightReport,
                        is_with_transcript: isWithTranscript,
                    });
                    break;

                // case ReportStatus.Error: TODO непонятно что делать в этом статусе
                default:
                    throw new Error('The report status is wrong');
            }
        } catch (error) {
            // TODO ASD-224 проработать как лучше обрабатывать ошибки (когда например недостаточно токенов)
            message.warning('Ooops, Not Enough Tokens') // TODO временно
            setError(error);
        } finally {
            setIsLoading(false);
            reloadReport();
        }
    }, [isWithInsightReport, isWithTranscript, reloadReport, report?.status, reportId, reportState]);

    return [{ isLoading, error }, fetchData];
};

interface GenerateButtonProps {
    disabled: boolean;
    text: string;
    loading: boolean;
}

const TEXT_BUTTON_GENERATE_REPORT = 'Generate Report';
const TEXT_BUTTON_REGENERATE_REPORT = 'Re-Generate Report';
const TEXT_BUTTON_REGENERATING_REPORT = 'Generating Report';
const TEXT_BUTTON_GENERATE_TRANSCRIPT = 'Generate Transcript';
const TEXT_BUTTON_REGENERATE_TRANSCRIPT = 'Re-Generate Transcript';

export const useGenerateButtonProps = () => {
    const { isWithInsightReportState, isWithTranscriptState } = useReportSetupContext();

    const { report } = useReportWidgetContext();
    const [isWithInsightReport] = isWithInsightReportState;
    const [isWithTranscript] = isWithTranscriptState;

    const [buttonProps, setButtonProps] = useState<GenerateButtonProps>({
        disabled: true,
        text: TEXT_BUTTON_REGENERATING_REPORT,
        loading: true,
    });

    useEffect(() => {
        if (report?.status === null) {
            setButtonProps({
                disabled: true,
                text: TEXT_BUTTON_GENERATE_REPORT,
                loading: false,
            });
        } else if (report?.status === ReportStatus.Uploaded) {
            setButtonProps({
                disabled: !isWithInsightReport && !isWithTranscript,
                text:
                    !isWithInsightReport && !isWithTranscript
                        ? 'Generate'
                        : isWithInsightReport
                          ? TEXT_BUTTON_GENERATE_REPORT
                          : TEXT_BUTTON_GENERATE_TRANSCRIPT,
                loading: false,
            });
        } else if (report?.status === ReportStatus.Finished) {
            setButtonProps({
                disabled: !isWithInsightReport && !isWithTranscript,
                text:
                    !isWithInsightReport && !isWithTranscript
                        ? 'Re-Generate'
                        : isWithInsightReport
                          ? TEXT_BUTTON_REGENERATE_REPORT
                          : TEXT_BUTTON_REGENERATE_TRANSCRIPT,
                loading: false,
            });
        } else if (report?.status === ReportStatus.Error) {
            console.log(ReportStatus.Error);
            setButtonProps({
                disabled: false,
                text: TEXT_BUTTON_REGENERATING_REPORT,
                loading: false,
            });
        } else {
            setButtonProps({
                disabled: true,
                text: TEXT_BUTTON_REGENERATING_REPORT,
                loading: true,
            });
        }
    }, [isWithInsightReport, isWithTranscript, report]);

    return { buttonProps };
};
