import { ReactNode, createContext, useContext, useEffect, useState } from 'react';

import { useReportWidgetContext } from './ReportWidgetContext';
import { ReportStatus } from 'src/@types/enums';
import { RecordNotification } from 'src/@types/websocket/notifications';
import { Language } from 'src/services/enums';

type State<T> = [T, React.Dispatch<React.SetStateAction<T>>];

export interface ReportSetupState {
    language?: Language | null;
    participant_count?: number | null;
    respondent_known_names: string[];
    goals?: string | null;
    notes?: string | null;
    report_language?: string | null;
    status?: ReportStatus | null;
    event?: RecordNotification | null;
}

interface ReportSetupContext {
    reportState: ReportSetupState;
    setReportState: React.Dispatch<React.SetStateAction<ReportSetupState>>;
    isWithTranscriptState: State<boolean>;
    isWithInsightReportState: State<boolean>;
}

const ReportWithSetupContext = createContext<ReportSetupContext | undefined>(undefined);

export const ReportWithSetupProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const { report } = useReportWidgetContext();
    const isWithTranscriptState = useState<boolean>(true);
    const isWithInsightReportState = useState<boolean>(true);

    const [, setIsWithInsightReport] = isWithInsightReportState;
    const [, setIsWithTranscript] = isWithTranscriptState;

    const [reportState, setReportState] = useState<ReportSetupState>({
        language: null,
        participant_count: null,
        respondent_known_names: [],
        goals: null,
        report_language: null,
        status: null,
        event: null,
    });

    useEffect(() => {
        const reportSetup = report?.setup;
        if (report?.status === ReportStatus.InProgress) {
            return;
        }
        if (reportSetup) {
            setIsWithInsightReport(reportSetup['is_with_insight_report']);
            setIsWithTranscript(reportSetup['is_with_transcript']);
            setReportState(prevState => ({
                ...prevState,
                ...reportSetup,
            }));
        } else {
            setIsWithInsightReport(true);
            setIsWithTranscript(true);
        }
    }, [report?.setup, report?.status, report?.transcripts.length, setIsWithInsightReport, setIsWithTranscript]);

    const initialContext = {
        reportState,
        setReportState,
        isWithTranscriptState,
        isWithInsightReportState,
    };

    return <ReportWithSetupContext.Provider value={initialContext}>{children}</ReportWithSetupContext.Provider>;
};

export const useReportSetupContext = () => {
    const context = useContext(ReportWithSetupContext);
    if (context === undefined) {
        throw new Error('useReportSetupContext must be used within a ReportWithSetupContext');
    }
    return context;
};

export const useIsSetupContextAvailable = () => {
    const context = useContext(ReportWithSetupContext);
    return Boolean(context);
};
