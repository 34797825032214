import React from 'react';

import { Collapse, Flex, Space, Typography, theme } from 'antd';

import { AseedText } from 'src/components/AseedTypography/AseedText/AseedText';
import { SourcesTooltip } from 'src/components/AseedTypography/AseedText/SourcesTooltip';
import { useReportTabsContext } from 'src/components/Report/ReportWidget/ReportTabController/ReportTabsContext';

import { Job } from 'src/@types/report';

const { Panel } = Collapse;
const { Text } = Typography;

interface IdentifiedJobsProps {
    prefix?: string;
    level?: number;
    jobs?: Job[];
}

export const IdentifiedJobs: React.FC<IdentifiedJobsProps> = ({ prefix = '', level = 0, jobs }) => {
    const { expandedKeysState } = useReportTabsContext();
    const [expandedKeys, setExpandedKeys] = expandedKeysState;

    const onPanelChange = (keys: string | string[]) => {
        setExpandedKeys(Array.isArray(keys) ? keys : [keys]);
    };

    if (!jobs) return null;

    const {
        token: { colorPrimary, colorTextSecondary, colorBgContainer },
    } = theme.useToken();

    const hoverStyle = `
        .hoverable-panel.expanded:hover {
            background-color: ${colorPrimary}10 !important;
        }
    `;

    const genJobTitle = (jobPrefix: string, title: string, isActive: boolean, examples? :any[]) => (
        <Space>
            {borderedTitle(jobPrefix, isActive, { fontSize: '14px' })}
            <AseedText active={isActive}>{title}</AseedText>
            { examples && examples.length > 0 && (
                <SourcesTooltip dialogExamples={examples} />
            )}
        </Space>
    );

    const borderedTitle = (title: string, isActive: boolean, additionalStyles?: React.CSSProperties) => (
        <Flex style={{ display: 'flex', width: 'fit-content' }}>
            <Text
                style={{
                    padding: '6px 6px',
                    backgroundColor: isActive ? colorBgContainer : '#6A6A7010',
                    color: isActive ? `#9B62FF` : '#6A6A70',
                    borderRadius: '6px',
                    fontSize: '14px',
                    lineHeight: '120%',
                    fontWeight: 600,
                    whiteSpace: 'nowrap',
                    ...additionalStyles,
                }}
            >
                {title}
            </Text>
        </Flex>
    );

    return (
        <>
            <style>{hoverStyle}</style>
            <Collapse
                expandIcon={({ isActive }) => (
                    <span
                        style={{
                            display: 'inline-block',
                            fontSize: '12px',
                            transform: `rotate(${isActive ? 90 : 0}deg)`,
                            transition: 'transform 0.3s',
                            width: '18px',
                            color: `${colorTextSecondary}50`,
                        }}
                    >
                        􀄧
                    </span>
                )}
                ghost
                activeKey={expandedKeys}
                onChange={onPanelChange}
            >
                {jobs.map((job, index) => {
                    const jobPrefix = prefix ? `${prefix}.${index + 1}` : `${index + 1}`;
                    const indentStyle = { marginLeft: jobPrefix.includes('.') ? '20px' : '0px' };
                    const isExpanded = expandedKeys.includes(`job-${jobPrefix}`);
                    const panelStyle = {
                        ...indentStyle,
                        ...(isExpanded
                            ? {
                                  borderRadius: '12px',
                                  paddingBottom: '24px',
                                  backgroundColor: '#F5F5FF90',
                              }
                            : {}),
                        transition: 'all 0.3s ease',
                        marginBottom: isExpanded ? '6px' : '0',
                    };

                    return (
                        <React.Fragment key={`job-${jobPrefix}`}>
                            <Panel
                                className={`hoverable-panel ${isExpanded ? 'expanded' : ''}`}
                                header={genJobTitle(jobPrefix, job.title, isExpanded, job.examples)}
                                key={`job-${jobPrefix}`}
                                style={panelStyle}
                                collapsible="header"
                            >
                                <Flex vertical gap={18}>
                                    <Flex vertical gap={6}>
                                        {
                                            <Flex align="center" style={{ paddingTop: '12px' }}>
                                                {borderedTitle('Job Story', isExpanded)}
                                            </Flex>
                                        }
                                        <Flex vertical gap={0}>
                                            {job.job_story_when && <AseedText>{job.job_story_when}</AseedText>}
                                            {job.job_story_want && <AseedText>{job.job_story_want}</AseedText>}
                                            {job.job_story_so_that && <AseedText>{job.job_story_so_that}</AseedText>}
                                        </Flex>
                                    </Flex>
                                    {job.dominant_solution && (
                                        <Flex vertical gap={6}>
                                            {borderedTitle('Dominant Solution', isExpanded)}
                                            <AseedText>{job.dominant_solution}</AseedText>
                                        </Flex>
                                    )}
                                    {job.problems_with_current_solution && (
                                        <Flex vertical gap={6}>
                                            {borderedTitle('Problems with solution', isExpanded)}
                                            <AseedText>{job.problems_with_current_solution}</AseedText>
                                        </Flex>
                                    )}
                                    {job.emotions && (
                                        <Flex vertical gap={6}>
                                            {borderedTitle('Emotions', isExpanded)}
                                            <AseedText>{job.emotions}</AseedText>
                                        </Flex>
                                    )}
                                </Flex>
                            </Panel>
                            {job.sub_jobs && job.sub_jobs.length > 0 && (
                                <IdentifiedJobs prefix={jobPrefix} level={++level} jobs={job.sub_jobs} />
                            )}
                        </React.Fragment>
                    );
                })}
            </Collapse>
        </>
    );
};
