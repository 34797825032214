import { useState } from 'react';

import { Tabs } from 'antd';

import { getAllKeys } from 'src/components/Report/helpers/JobKeys';

import { useIsSetupContextAvailable } from '../ReportSetupContext';
import { useReportTabsContext } from './ReportTabsContext';
import { ReportInsights, ReportJTBD, ReportProfile, ReportRecommendations, ReportSetup, ReportTranscript } from './Tabs';
import { ErrorTabContent } from './Tabs/ErrorTabContent';
import { LoadingContent } from './Tabs/LoadingContent';
import { ReportStatus } from 'src/@types/enums';
import { DetailedReportResponse } from 'src/@types/jtbd';
import styles from './ReportTabs.module.scss'

interface ReportTabsProps {
    report: DetailedReportResponse;
    reportId?: string;
}

const ReportTabs: React.FC<ReportTabsProps> = ({ report, reportId }) => {
    const { expandedKeysState } = useReportTabsContext();
    const isSetupContextAvailable = useIsSetupContextAvailable();

    const [activeTab, setActiveTab] = useState<string>(getDefaultActiveTab(report));

    const [expandedKeys, setExpandedKeys] = expandedKeysState;

    const toggleExpandAll = () => {
        const allKeys = getAllKeys(report);
        setExpandedKeys(expandedKeys.length > 0 ? [] : allKeys);
    };

    const handleTabChange = (activeKey: string) => {
        setActiveTab(activeKey);
    };

    const showExpandAllButton = !['setup', 'transcript'].includes(activeTab);
    const isSetupAvailable = reportId === undefined ? false : isSetupContextAvailable && report.setup;

    const items = [
        isSetupAvailable && {
            key: 'setup',
            label: '􀍟 Setup',
            children: <ReportSetup />,
        },
        report.transcripts &&
            report.transcripts.length > 0 && {
                key: 'transcript',
                label: '􀌮 Transcript',
                children: <ReportTranscript transcripts={report.transcripts} />,
            },
        report.profile && {
            key: 'profile',
            label: '􀉮 Profile',
            children: <ReportProfile profile={report.profile} />,
        },
        report.insights && {
            key: 'insights',
            label: '􀫸 Insights',
            children: <ReportInsights insights={report.insights} />,
        },
        report.jtbd && {
            key: 'jtbd',
            label: '􀵫 JTBD',
            children: <ReportJTBD jtbd={report.jtbd} />,
        },
        report.recommendations && {
            key: 'recommendations',
            label: '􂨪 Recommendations',
            children: <ReportRecommendations recommendations={report.recommendations} />,
        },
    ].filter(Boolean);

    if (report.status === ReportStatus.Error) {
        return <ErrorTabContent />;
    }

    if (report.status === ReportStatus.InQueue || report.status === ReportStatus.InProgress) {
        return <LoadingContent />;
    }

    return (
        <Tabs
            type="card"
            defaultActiveKey={activeTab}
            onChange={handleTabChange}
            items={items}
            tabBarExtraContent={
                showExpandAllButton &&
                report.status === ReportStatus.Finished && (
                    <button className={actionButton} onClick={toggleExpandAll}>{expandedKeys.length > 0 ? 'Collapse all' : 'Expand all'}</button>
                )
            }
        />
    );
};

export default ReportTabs;

const getDefaultActiveTab = (report: DetailedReportResponse): string => {
    if (report.profile) return 'profile';
    if (report.transcripts && report.transcripts.length > 0) return 'transcript';
    return 'setup';
};

const { actionButton } = styles;