import { Card } from 'antd';

interface MenuCardProps {
    children: React.ReactNode;
    style?: React.CSSProperties;
}

export const MenuCard: React.FC<MenuCardProps> = ({ children, style }) => (
    <Card
        style={{
            borderRadius: 24,
            ...style,
        }}
        styles={{
            body: {
                width: '100%',
                height: '100%',
                padding: 0,
            },
        }}
    >
        {children}
    </Card>
);
