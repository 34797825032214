import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import { Spin } from 'antd';

import { PrivateRoute, PublicRoute } from 'src/components/PrivateRoute';

import { PATH } from '../constants/paths';
import StartLayout from '../layouts/StartLayout';
import { Home } from '../pages/Home';
import { NoMatch } from '../pages/NoMatch';
import { MainLayout } from 'src/layouts/MainLayout';
import React from 'react';
import { UploadFilesProvider } from '../components/UploadedFiles/UploadFilesContext';

export const Routes = () => {
    const router = createBrowserRouter([
        {
            element: <PublicRoute />,
            children: [
                {
                    path: PATH.HOME,
                    element: <StartLayout />,
                    children: [
                        {
                            index: true,
                            element: <Home />,
                        },
                        {
                            path: '*',
                            element: <NoMatch />,
                        },
                    ],
                },
            ],
        },
        {
            element:(
                <UploadFilesProvider>
                    <PrivateRoute />
                </UploadFilesProvider>
            ),
            children: [
                {
                    path: PATH.HOME,
                    element: <MainLayout />,
                    children: [
                        {
                            path: PATH.WORKSPACE,
                            async lazy() {
                                const { WorkSpace } = await import('../pages/WorkSpace');
                                return { Component: WorkSpace };
                            },
                        },
                        {
                            path: `${PATH.REPORTS}/:id`,
                            async lazy() {
                                const { ReportSpace } = await import('../pages/ReportSpace');
                                return { Component: ReportSpace };
                            },
                        },
                        {
                            path: '*',
                            element: <NoMatch />,
                        },
                    ],
                },
            ],
        },
    ]);

    return <RouterProvider router={router} fallbackElement={<Spin />} />;
};
