import { NotificationInstance } from 'antd/es/notification/interface';
import { RecordNotificationStatus } from 'src/@types/websocket/enums';
import { RecordNotification } from 'src/@types/websocket/notifications';

export const provideNotification = (event: RecordNotification, api: NotificationInstance) => {
    let notificationType: 'success' | 'info' | 'warning' | 'error';
    let message: string;
    let description: string;

    switch (event.status) {
        case RecordNotificationStatus.InProgress:
            notificationType = 'info';
            message = 'Report in Progress';
            description = `Report "${event.title}" now in process`;
            break;
        case RecordNotificationStatus.Finished:
            notificationType = 'success';
            message = 'Wow! Report is done';
            description = `Report "${event.title}" successfully finished`;
            break;
        case RecordNotificationStatus.Error:
            notificationType = 'error';
            message = 'Oops! Something went wrong';
            description = `Error while processing report "${event.title}", don't worry!`;
            break;
        default:
            notificationType = 'warning';
            message = 'Hmm! Something went wrong';
            description = `Something went wrong while gnerarint "${event.title} report"`;
    }

    api[notificationType]({
        message,
        description,
        placement: 'bottomLeft',
        duration: 10,
    });
};
